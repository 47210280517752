import './css/footer.css'
import logo from '../cdn/pollogang.png'


function Footer() {

    return (

        <div className="footer-todo">

            <a>Todos los derechos reservados © PolloGang</a>

        </div>

    );
}

export default Footer;
